import React, { useState, useEffect } from 'react';
import Banner from '../components/Banner';
import Speciality from '../components/Speciality';
import Freedum from '../components/Freedum';
import Cat from '../components/Cat';

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <div className=' w-full  min-h-screen flex flex-col justify-center items-center'>
      
        <Cat />
        {/* <Freedum /> */}
          {/* <Banner /> */}
          {/* <Speciality /> */}
      
    </div>
  );
};

export default Home;
